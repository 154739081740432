<template>
  <transition-group name="flip-list" tag="div">
    <div class="item collapse-panel-item" v-for="(litem,lind) in list" :key="lind + 'i'">
      <div class="panel-header flex flex_jcbetween flex_acenter">
        <div class="panel-header-content flex flex_acenter">
          <div class="panel-header-title fontsize_14 color_222">{{litem.name}}({{litem.code}})</div>
          <checkBox :value="litem.initLanguage" :label="'设为初始语言'" labelFontSize="12" @change="changeCheck($event,litem.key_name)"></checkBox>
          <checkBox :value="litem.currentEnv" :label="'设为当前环境'" labelFontSize="12" @change="changeEnv($event,litem.key_name)"></checkBox>
        </div>
        <div class="panel-header-tools flex flex_acenter" @click.stop="">
          <!-- <i class="icon-edit-black pointer" @click="edit(litem,lind)" :class="{'isdisabled': lind === 0}">
            <span class="icon-tips fontsize_12 color_222">编辑</span>
          </i>
          <i class="icon-page-copy pointer" @click="copy(litem,lind)" :class="{'isdisabled': lind === (list.length - 1)}">
            <span class="icon-tips fontsize_12 color_222">复制</span>
          </i> -->
          <i class="icon-menu-del pointer all_del_icon icon_color_222 icon_hover_E74362" @click="remove(litem,lind)">
            <span class="icon-tips fontsize_12 color_222">{{$t('lang.delete')}}</span>
          </i>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  props: {
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
    // currentChecked: {
    //   type: [Number, String],
    //   default:0,
    //   validator(val) {
    //     return !/\d/.test(val) && val >= 0
    //   }
    // }
  },
  data() {
    return {

    };
  },
  components: {
    checkBox
  },
  methods: {
    changeCheck() {},
    changeEnv() {},
    edit() {},
    copy() {},
    remove() {},
  },
}
</script>

<style lang="less" scoped>
  /deep/.el-checkbox {
    margin-right: 5px;
  }
  .flip-list-move {
    transition: transform 1s;
  }
  .collapse-panel-item {
    box-sizing: border-box;
    margin-bottom: 8px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    &.is-active {
      .panel-header {
        background-color: #b0e0f6;
      }
    }
  }
  // 头部
  .panel-header {
    box-sizing: border-box;
    padding: 8px 8px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
  .panel-header-content {
    padding-left: 8px;
    .panel-header-title {
      // padding-left: 14px;
      width: 144px;
    }
  }
  .panel-header-tools {
    border-radius: 4px;
    height: 30px;
    // background-color: #F7F7F7;
    >i {
      position: relative;
      width: 33px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #505050;
      &:hover {
        background-color: #e4e4e4;
        .icon-tips {
          display: block;
        }
      }
      &::after {
        position: absolute;
        right: 0.5px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: block;
        width: 1px;
        height: 10px;
        background-color: #E4E4E4;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        &::after {
          display: none;
        }
      }
      &.isdisabled {
        color: #ccc;
        cursor: auto;
        &:hover {
          background-color: #F7F7F7;
          .icon-tips {
            display: none;
          }
        }
      }
      .icon-tips {
        display: none;
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateY(100%) translateX(-50%);
        background: #F2F2F1;
        border: 1px solid #DADADA;
        border-radius: 1px;
        padding: 0 6px;
        height: 17px;
        line-height: 17px;
        white-space:nowrap;
      }
    }
  }
</style>