<template>
  <div class="tab-card-container">
    <el-tabs type="border-card" stretch class="">
      <el-tab-pane v-for="(item, index) in setting" :key="item.id">
        <span slot="label" class="fontsize_12 color_222">{{item.tab_group_name}}</span>
        <div class="scroll-box">
          <div  >
            <div v-if="item.tab_group_key == 'tab-style'" class="padding_left20 padding_top20">
              <p class="margin_bot8 fontsize_14 color_222">语言选择器图标</p>
              <div class="language-icon-wrap flex flex_center flex_acenter pointer">
                <i class="icon-language-gray fontsize_20"></i>
              </div>
            </div>
            <ScaffoldForm 
              scrollBoxPaddingLR="20"
              titleFontSize="14"
              :ruleForm="form"
              :propList="item.setting_list"
              :ckeditorNameId="'languagePicker'"
              @handleClick="handleClick"
              @selectChange="formChangeBlur" 
              @formItemBlur="formChangeBlur">
            </ScaffoldForm>
            <div v-if="item.tab_group_key == 'tab-content'" class="padding_left20">
              <p class="margin_bot8 fontsize_14 color_222">语言</p>
              <div class="language-list margin_bot24">
                <LanguageItem :list="codeList"></LanguageItem>
              </div>
              <div class="language-add-btn pointer" @click="addLanguage">
                <i class="icon-course-add fontsize_12 color_E74362"></i>
                <span class="fontsize_12 color_E74362 margin_left4">添加</span>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      
    </el-tabs>
  </div>
</template>

<script>
import ScaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import LanguageItem from "@/renderer/components/common/languageItem.vue";
export default {
  props:{
    setting: {
      type: Array,
      default: function() {
        return []
      }
    },
    form: {
      type: Object,
      default: function() {
        return {}
      }
    },
  },
  components: {
    ScaffoldForm,
    LanguageItem
  },
  data() {
    return{
      codeList:[
        {
          name:"中文",
          code:"CH",
          initLanguage:true,
          currentEnv:true,
        },
        {
          name:"English",
          code:"EN",
          initLanguage:false,
          currentEnv:false,
        }
      ]
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    formChangeBlur(value,propName,leftForm) {
      // let obj = {};
      // obj[propName] = value;
      
      // this.saveDetail(obj,leftForm);
    },
    addLanguage() {
      console.log("添加新的一项语言，未接入");
    },
  }
}
</script>

<style lang="less" scoped>
  @tabHei: 36px; //tabs标签页高度
  .tab-card-container {
    /deep/.el-tabs--border-card {
      box-shadow: none;
      box-sizing: border-box;
      border: 1px solid #E4E4E4;
      border-radius: 4px;
      height: 399px;
      background-color: #FFFFFF;
      .el-tabs__nav-wrap {
        margin-bottom: 0;
      }
      &>.el-tabs__content {
        box-sizing: border-box;
        padding: 0;
      }
      .el-tabs__content{
        height: calc(100% - @tabHei);
      }
      &>.el-tabs__header .el-tabs__item {
        padding:0 !important;
        border: none;
      }
      .el-tabs__item {
        height: @tabHei;
        line-height: @tabHei;
      }
      &>.el-tabs__header {
        border-bottom: none;
        background-color: #F0F0F0;
      }
      &>.el-tabs__header .el-tabs__item.is-active {
        border: none;
        span {
          position: relative;
          box-sizing: border-box;
          display: inline-block;
          padding: 0 6px;
          color: #E74362;
          &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #E74362;
          }
        }
      }
    }
  }

  .language-list {
    // padding-right: 20px;
  }

  .language-add-btn {
    box-sizing: border-box;
    border: 1px solid #E74362;
    border-radius: 14.5px;
    width: 70px;
    height: 29px;
    text-align: center;
    line-height: 27px;
  }

  .language-icon-wrap {
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    width: 36px;
    height: 36px;
  }
</style>