<template>
  <el-form @submit.native.prevent action="false" :model="ruleForm" class="height_100p">
    <div class="scroll-parent hidden">
      <div class="scroll-box padding_0_15">
        <div v-for="(sitem,gind) in propList" :key="sitem.id" class="global-sets-item" 
          :class="{'truefalse-class': sitem['input_type'] === 'truefalse', 
          'condition-class': sitem['properties_name'] == '_requireCompletionOf', 
          'languagePicker-class': sitem['name'] == 'config-_languagePicker-properties-_isEnabled'}">
          <p class="title margin_top24" >{{ sitem.title }}</p>
          <p v-if="sitem.help" class="fontsize_12 color_A1A1A1 margin_bot10">{{sitem.help}}</p>
          <div v-if="sitem.properties" :class="{'flex': sitem.properties_name == '_backgroundStyles'}">
            <div v-for="(ritem, prind) in sitem.properties" :key="ritem.id" 
              :class="{'margin_bot20': prind !== sitem.properties.length - 1 && ritem['input_type'] != 'Checkbox', 
              'set-bgimg-select-container left':  ritem.display == 1 && ritem['input_type'] == 'single_select',
              'switch-class':  ritem.display == 1 && ritem['input_type'] == 'switch'}">
              <p class="margin_bot8" v-if="ritem.display == 1 && ritem['input_type'] == 'number' || ritem.display == 1 && ritem['input_type'] == 'switch' ">{{ritem.title}}</p>
              <p v-if="sitem['input_type'] == 'group_title' && ritem.display == 1 && ritem['input_type'] == 'switch' " class="fontsize_12 color_A1A1A1">{{ritem.description}}</p>
              <ckeditor 
                v-if=" ritem.display == 1 && ritem['input_type'] == 'richtext_mini'"
                type="simple" :id=" 'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" :value="ruleForm[ritem.name]"
                :placeholder="ritem.placeholder"
                @input="richTextInput($event,ritem.name)" @blur="formItemBlur($event,sitem.name)"
                ></ckeditor>
              <ckeditor 
                v-else-if="  ritem.display == 1 && ritem['input_type'] == 'richtext' "
                :type="'rich'" :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" :value="ruleForm[ritem.name]" 
                @input="richTextInput($event,ritem.name)" @blur="formItemBlur($event,sitem.name)"
                :placeholder="ritem.placeholder"
                ></ckeditor>
              <div v-else-if="ritem.display == 1 && (ritem['input_type'] == 'number' || ritem['input_type'] == 'untitled_number')" class="flex flex_acenter">
                <NumberInput
                  borderColor="#D1D1D1"
                  :value="ruleForm[ritem.name]/( ritem.unit_value_multiple ? ritem.unit_value_multiple : 1 )"
                  :rightTips="ritem.unit"
                  :valueMultiple="ritem.unit_value_multiple"
                  :placeholder="ritem.placeholder"
                  @input="changeVal($event,ritem.name)"
                  @blur="formItemBlur($event.value,ritem.name)"
                ></NumberInput>
              </div>
              <el-select
                v-else-if=" ritem.display == 1 && ritem['input_type'] == 'single_select' "
                :value="ruleForm[ritem.name]"
                @change="selectChange($event,ritem.name)"
                placeholder="请选择"
                class="set-bgimg-select noborder" >
                <el-option
                  v-for="item in ritem.enum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value" >
                </el-option>
              </el-select>
              <assetupload
                v-else-if=" ritem.display == 1 && ritem['input_type'] == 'image' "
                :src="ruleForm[ritem.name]"
                width="200"
                height="117"
                bgColor="#F4F4F4"
                :uploadText="ritem.upload_text"
                @change="uploadImg($event,ritem.name)" >
              </assetupload>
              <checkBox v-else-if=" ritem.display == 1 && ritem['input_type'] == 'truefalse'" 
                :value="!!ruleForm[ritem.name]" :label="ritem.title" @change="formItemBlur($event,ritem.name)" class="margin_bot24">
              </checkBox>
              <el-switch
                v-else-if="
                ritem.display == 1 && ritem['input_type'] == 'switch'" 
                @change="switchChange($event,ritem.name)"
                v-model="ruleForm[ritem.name]"
                :width="52"
                active-color="#E74362"
                inactive-color="#D4D4D4"
                class="margin_top8">
              </el-switch>
            </div>
          </div>
          <ckeditor v-if="sitem['input_type'] == 'richtext_mini'"
            :type="'simple'" 
            :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
            :value="ruleForm[sitem.name]" @input="richTextInput($event,sitem.name)" 
            :placeholder="sitem.placeholder"
            @blur="formItemBlur($event,sitem.name)"
            >
          </ckeditor>
          <ckeditor v-else-if="sitem['input_type'] == 'richtext'" 
            :type="'rich'" 
            :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
            :value="ruleForm[sitem.name]" 
            :placeholder="sitem.placeholder"
            @input="richTextInput($event,sitem.name)" 
            @blur="formItemBlur($event,sitem.name)"
            >
          </ckeditor>
          <div v-else-if="sitem['input_type'] == 'number' || sitem['input_type'] == 'untitled_number'" class="flex flex_acenter width_100p">
            <NumberInput
              borderColor="#D1D1D1"
              :value="ruleForm[sitem.name]/( sitem.unit_value_multiple ? sitem.unit_value_multiple : 1 )"
              :rightTips="sitem.unit"
              :valueMultiple="sitem.unit_value_multiple"
              :placeholder="sitem.placeholder"
              @input="changeVal($event,sitem.name)"
              @blur="formItemBlur($event.value,sitem.name)" >
            </NumberInput>
          </div>
          <el-select
            v-else-if="sitem['input_type'] == 'single_select'"
            :value="ruleForm[sitem.name]"
            @change="selectChange($event,sitem.name)"
            placeholder="请选择"
            class="set-bgimg-select noborder">
            <el-option
              v-for="item in sitem.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <checkBox v-else-if="sitem['input_type'] == 'checkbox'" 
              :value="!!ruleForm[sitem.name]" :label="sitem.title" @change="formItemBlur($event,sitem.name)" class="margin_bot24">
          </checkBox>
          <div v-else-if="sitem['input_type'] == 'text'" class="global-sets-item"  >
            <el-input v-model="ruleForm[sitem.name]" :placeholder="sitem.placeholder" class="modal-sets-inlineinput noborder bgc_fff" @input="()=>{$forceUpdate()}" @blur="formItemBlur($event.target.value,sitem.name)"></el-input>
          </div>
          <div v-else-if="sitem['input_type'] == 'textarea'" class="global-sets-item" >
            <ace :value="ruleForm[sitem.name]" @input="changeLessCode" @blur="formItemBlur($event,sitem.name)" ></ace>
          </div>
          <assetupload
            v-else-if="sitem['input_type'] == 'image'"
            :src="ruleForm[sitem.name]"
            width="200"
            height="117"
            bgColor="#F4F4F4"
            :uploadText="sitem.upload_text"
            @change="uploadImg($event,sitem.name)">
          </assetupload>
          <AltInput v-else-if="sitem['input_type'] == 'alt'"
            :placeholder="sitem.title"
            :value="ruleForm[sitem.name]"
            @input="changeVal($event,sitem.name)"
            @blur="formItemBlur($event,sitem.name)">
          </AltInput>
          <el-switch
            v-else-if="sitem['input_type'] == 'truefalse'" 
            @change="switchChange($event,sitem.name)"
            v-model="ruleForm[sitem.name]"
            :width="52"
            active-color="#E74362"
            inactive-color="#D4D4D4"
            class="">
          </el-switch>
          <div v-else-if="sitem['name'] == 'config-_languagePicker-properties-_isEnabled'">
            <div class="flex flex_acenter margin_bot18">
              <p class="fontsize_14 color_222">{{ sitem.title }}</p>
              <el-switch
                @change="switchChange($event,sitem.name)"
                v-model="ruleForm[sitem.name]"
                :width="52"
                active-color="#E74362"
                inactive-color="#D4D4D4"
                class="margin_left16">
              </el-switch>
            </div>
            <TabCard v-if="sitem['name'] == 'config-_languagePicker-properties-_isEnabled'"
              :setting="languageTabs" :form="languageForm" >
            </TabCard>
          </div>
          <div v-else-if="sitem.input_type === 'checkbox_group'">
             <checkBox v-for="(gitem,gind) in sitem.input_list" :key="'sg'+gind" :value="!!ruleForm[gitem.name]" :label="gitem.title" @change="formItemBlur($event,gitem.name)" class="margin_bot24"></checkBox>
          </div>
          <div v-else-if="sitem.input_type === 'text_group'">
            <label for="" v-for="gitem in sitem.input_list" :key="gitem.name" class="flex flex_acenter margin_bot8">
              <span class="padding_right16 flexshrink" v-if="gitem.input_type === 'prefix_text'">{{gitem.title}}</span>
              <el-input   v-model="ruleForm[gitem.name]" placeholder="" class="modal-sets-inlineinput noborder bgc_fff" @blur="formItemBlur($event.target.value,gitem.name)"></el-input>
            </label>
          </div>
          <DraggableList v-else-if="sitem.input_type == 'list' && sitem.name === '_startIds'" :list="ruleForm._startIds" @updateSort="updateSort"></DraggableList>
          <div v-else-if="sitem.input_type == 'list' && sitem.name === '_resourcesItems'">
            <ResourceItem :list="ruleForm._resourcesItems" :setProps="sitem.item" 
              @edit="(eitem,eind)=>{$emit('editResource',eitem,eind,sitem.item_properties.add)}" 
              @del="(eitem,eind)=>{$emit('delResource',eitem,eind)}" 
              @changeCheck="(args)=>{$emit('changeCheck',args)}"></ResourceItem>
            <customButton v-if="sitem.buttonText" plain color="#E74362" :width="52" :height="30"  @click="addResource(sitem.item_properties.add, ruleForm)" class="sureBtn pointer">
              <span class="fontsize_12">{{sitem.buttonText}}</span>
            </customButton>
          </div>
          <!-- <div class="set-bgcolor global-sets-item">
            <p class="title">背景色</p>
            <div class="prel set-bgcolor-picker-wrapper">
              <el-color-picker
              class="set-bgcolor-picker"
              v-model="color"
              :show-alpha="false"
              color-format="hex"
              @change="colorChange"
              @active-change="colorActiveChange"
              :predefine="predefineColors">
            </el-color-picker>
            <span class="picker-colorvalue">{{color}}</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import AltInput from "@/renderer/components/common/altInput.vue";
import DraggableList from "@/renderer/components/common/draggableList.vue";
import TabCard from "@/renderer/components/common/tabCard.vue";
import ResourceItem from "@/renderer/components/common/resourceItem.vue";
import customButton from "@/renderer/components/common/button.vue";
import languageJson from "@/0122.json";
export default {
  name:"leftForm",
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    fieldType: { //当前tab类型
      type: [String, Number],
      required: true,
      default:""
    },
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
    propList:{
      type: Object,
      default: function() {
        return {};
      }
    },
    // startIds:{ //首页排序列表
    //   type: Array,
    //   default: function() {
    //     return [];
    //   }
    // },
  },
  components: {
    NumberInput,
    checkBox,
    AltInput,
    DraggableList,
    TabCard,
    ResourceItem,
    customButton
  },
  data() {
    return {
      activeTab: 0,
      //预定义颜色
      predefineColors: [
        'rgba(255, 69, 0, 0.68)', //为原始值，做历史记录用
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      languageForm: {}
    };
  },
  computed: {
    languageTabs() {
      return languageJson.tabgroup_list;
    }
  },
  methods: {
    ...mapMutations(["SET_COURSE_FORM"]),
    addResource(propList, form) {
      this.$emit('addResource',propList, form);
    },
    switchChange(val,prop) {
      // 开关切换
      this.$emit("formItemBlur", {
        value: val, 
        prop: prop,
        ruleForm: this.ruleForm,
        fieldType: this.fieldType
      })
    },
    changeLessCode(val) {
      // this.lessCss = val;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    uploadImg(val,propName) {
      this.$emit("formItemBlur", {
        value: val, 
        prop: propName,
        ruleForm: this.ruleForm,
        fieldType: this.fieldType
      })
    },
    changeVal(value, prop) {
      this.$emit("changeVal",value,prop,this.ruleForm);
    },
    richTextInput(value, prop) {
      this.$emit("changeVal",value,prop,this.ruleForm)
    },
    formItemBlur(value, prop) {
      this.$emit("formItemBlur", {
        value: value, 
        prop: prop,
        ruleForm: this.ruleForm,
        fieldType: this.fieldType
      })
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.$emit('selectChange',{
        value: e,
        prop: prop,
        ruleForm: this.ruleForm,
        fieldType: this.fieldType
      })
    },
    updateSort(list) {
      this.$emit("updateSort",list);
    },
  }
}
</script>

<style lang="less" scoped>
/deep/.panel-header-tools > i:last-child {
  border-radius: 4px;
}
</style>