<template>
  <div class="draggable-list-container">
    <!--使用draggable组件-->
    <draggable v-if="drapListLen > 0" v-model="drapList" :disabled="disabled" 
      chosenClass="chosen" forceFallback="true" ghostClass="ghostClass" group="people" animation="1000" 
      filter=".panel-header-tools" @start="onStart" @end="onEnd" @sort="onSort">
      <transition-group>
        <div class="item collapse-panel-item" v-for="(litem,lind) in drapList" :key="litem._id">
          <div class="panel-header flex flex_jcbetween flex_acenter">
            <div class="panel-header-content flex flex_acenter">
              <i :class="[ litem._type === 'menu' ? 'icon-menu-theme': 'icon-menu-content']" class="fontsize_18"></i>
              <!-- <div class="panel-header-title pover custom-tree-label-html fontsize_14 color_222" v-html="litem.title"></div> -->
              <div class="panel-header-title pover custom-tree-label-html fontsize_14 color_222" v-html="litem.title"></div>
            </div>
            <div class="panel-header-tools flex flex_acenter" @click.stop="">
              <i class="icon-menu-del pointer all_del_icon icon_color_222 icon_hover_E74362" @click="remove(litem,lind)">
                <span class="icon-tips fontsize_12 color_222">{{$t('lang.delete')}}</span>
              </i>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable> 
    <div v-else class="fontsize_12 color_999">
      暂无数据
    </div>
  </div>
</template>
<script>
//导入draggable组件
import draggable from 'vuedraggable'
export default {
  //注册draggable组件
  components: {
    draggable,
  },
  props:{
    list: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return { 
      drag:false,
      //定义要被拖拽对象的数组
      drapList:[] ,
      disabled: false, //禁用拖动
      timer:null,
    };
  },
  computed: {
    drapListLen() {
      return this.drapList.length
    }
  },
  watch: {
    list: {
      deep: true,
      handler(n) {
        this.drapList = [].concat(n)
      }
    },
  },
  created() {
    this.drapList = [].concat(this.list)
  },
  methods: {
    //开始拖拽事件
    onStart(){
      this.drag=true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
      // console.log(this.list);
    },
    onSort(evt) {
      // console.log('onSort',evt,this.drapList);
      this.$emit('updateSort',JSON.parse(JSON.stringify(this.drapList)));
    },
    remove(item,index) {
      // console.log('remove',index,item);
      let targetItem = this.drapList[index];
      if(targetItem._id === item._id) {
        this.drapList.splice(index,1)
      }else {
        let targetIndex = this.drapList.findIndex(v => v._id === item._id)
        if(targetIndex >= 0) this.drapList.splice(targetIndex,1)
      }
      this.$emit('updateSort',JSON.parse(JSON.stringify(this.drapList)));
    }
  },
};
</script>
<style lang="less" scoped>
  // 过渡 - 仅在上下排序生效，防止和draggable组件 有冲突
  .flip-list-move {
    transition: transform 1s;
  }
  .draggable-list-container {
    margin-bottom: 24px;
    width: calc(100% - 5px);
  }
  /*被拖拽对象的样式*/
  .item {
    cursor: move;
  } 
  /*选中样式*/
  .chosen {
    
  }
  /*停靠位置样式*/
  .ghostClass {
    opacity: 0.5 !important;
    border-style: dashed !important;
  }

  .collapse-panel-item {
    box-sizing: border-box;
    margin-bottom: 8px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    &.is-active {
      .panel-header {
        background-color: #b0e0f6;
      }
    }
  }
  // 头部
  .panel-header {
    box-sizing: border-box;
    padding: 8px 8px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
  .panel-header-content {
    padding-left: 8px;
    .panel-header-title {
      padding-left: 14px;
      width: 235px;
    }
  }
  .panel-header-tools {
    border-radius: 4px;
    height: 30px;
    background-color: #F7F7F7;
    >i {
      position: relative;
      width: 33px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #505050;
      &:hover {
        background-color: #e4e4e4;
        .icon-tips {
          display: block;
        }
      }
      &::after {
        position: absolute;
        right: 0.5px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: block;
        width: 1px;
        height: 10px;
        background-color: #E4E4E4;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
        &::after {
          display: none;
        }
      }
      &.isdisabled {
        color: #ccc;
        cursor: auto;
        &:hover {
          background-color: #F7F7F7;
          .icon-tips {
            display: none;
          }
        }
      }
      .icon-tips {
        display: none;
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateY(100%) translateX(-50%);
        background: #F2F2F1;
        border: 1px solid #DADADA;
        border-radius: 1px;
        padding: 0 6px;
        height: 17px;
        line-height: 17px;
        white-space:nowrap;
      }
    }
  }
</style>