<template>
  <el-form @submit.native.prevent action="false" :model="ruleForm" class="height_100p" :class="{ 'one_tab': tabListLen == 1 }">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleClick"
      :stretch="true"
      :lazy="true"
      v-if="activeTab >= 0"
      :style="{'--tabs-num': tabsLength}"
    >
      <el-tab-pane
        v-for="(pitem, pind) in tabList"
        :key="pitem.id"
        :name="pind + ''"
      >
        <span slot="label" class="fontsize_14" :class="{'color_222': activeTab==pind, 'color_A1A1A1': activeTab!=pind}">{{ pitem.tab_group_name}}</span>
        <div class="scroll-parent hidden">
          <div class="scroll-box padding_0_16">
            <div v-for="sitem in pitem.setting_list" :key="sitem.id" class="modal-sets-item">
              <p class="title padding_bot16" v-if="sitem.display == 1">{{ sitem.title }}</p>
              <p v-if="sitem.display == 1 && sitem.help" class="fontsize_12 color_8C margin_bot10">{{sitem.help}}</p>
              <div v-if="sitem.properties" :class="{'flex': sitem.properties_name == '_backgroundStyles'}">
                <!-- 'margin_bot20': prind !== sitem.properties.length - 1 && ritem['input_type'] != 'Checkbox', -->
                <div v-for="(ritem, prind) in sitem.properties" :key="ritem.id" :class="{ 'set-bgimg-select-container left':  ritem.display == 1 && ritem['input_type'] == 'single_select' }">
                  <p class="margin_bot8" v-if="ritem.display == 1 && ritem['input_type'] == 'number'">{{ritem.title}}</p>
                  <ckeditor 
                    v-if=" ritem.display == 1 && ritem['input_type'] == 'richtext_mini'"
                    type="simple" 
                    :id=" 'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                    :value="ruleForm[ritem.key_name]" 
                    :throttle="throttleDelay"
                    :placeholder="ritem.placeholder"
                    @input="richTextInput($event,ritem.key_name)" 
                    @blur="formItemBlur($event,sitem.key_name)" >
                  </ckeditor>
                  <ckeditor 
                    v-else-if=" ritem.display == 1 && ritem['input_type'] == 'richtext' "
                    :type="'rich'" :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                    :value="ruleForm[ritem.key_name]" 
                    :throttle="throttleDelay"
                    :placeholder="ritem.placeholder"
                    @input="richTextInput($event,ritem.key_name)" 
                    @blur="formItemBlur($event,sitem.key_name)" >
                  </ckeditor>
                  <div v-else-if="ritem.display == 1 && (ritem['input_type'] == 'number' || ritem['input_type'] == 'untitled_number')" class="flex flex_acenter">
                    <NumberInput
                      :value="ruleForm[ritem.key_name]/( ritem.unit_value_multiple ? ritem.unit_value_multiple : 1 )"
                      :rightTips="ritem.unit"
                      :valueMultiple="ritem.unit_value_multiple"
                      :placeholder="ritem.placeholder"
                      @input="changeVal($event,ritem.key_name)"
                      @blur="formItemBlur($event.value,ritem.key_name)"
                    ></NumberInput>
                  </div>
                  <el-select v-else-if=" ritem.display == 1 && ritem['input_type'] == 'single_select' "
                    :value="ruleForm[ritem.key_name]"
                    @change="selectChange($event,ritem.key_name)"
                    placeholder="请选择"
                    class="set-bgimg-select noborder flex1" >
                    <el-option
                      v-for="item in ritem.enum"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <assetupload v-else-if=" ritem.display == 1 && ritem['input_type'] == 'image' "
                    :src="ruleForm[ritem.key_name]"
                    @change="uploadImg($event,ritem.key_name)" >
                  </assetupload>
                  <checkBox v-else-if=" ritem.display == 1 && ritem['input_type'] == 'checkbox'" 
                    :value="!!ruleForm[ritem.key_name]" 
                    :label="ritem.title" 
                    @change="formItemBlur($event,ritem.key_name)" 
                    class="margin_bot24">
                  </checkBox>
                  <el-switch
                    v-else-if="ritem.display == 1 && ritem['input_type'] == 'truefalse'" 
                    @change="switchChange($event,ritem.key_name)"
                    v-model="ruleForm[ritem.key_name]"
                    :width="52"
                    active-color="#E74362"
                    inactive-color="#D4D4D4"
                    class="">
                  </el-switch>
                </div>
              </div>
              <ckeditor v-if=" sitem.display == 1 && sitem['input_type'] == 'richtext_mini' "
                :type="'simple'" 
                :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
                :value="ruleForm[sitem.key_name]" 
                :throttle="throttleDelay"
                :placeholder="sitem.placeholder"
                @input="richTextInput($event,sitem.key_name)" 
                @blur="formItemBlur($event,sitem.key_name)" >
              </ckeditor>
              <ckeditor v-else-if=" sitem.display == 1 && sitem['input_type'] == 'richtext' "
                :type="'rich'" 
                :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
                :value="ruleForm[sitem.key_name]" 
                :throttle="throttleDelay"
                :placeholder="sitem.placeholder"
                @input="richTextInput($event,sitem.key_name)" 
                @blur="formItemBlur($event,sitem.key_name)" >
              </ckeditor>
              <div v-else-if="sitem.display == 1 && (sitem['input_type'] == 'number' || sitem['input_type'] == 'untitled_number')" class="flex flex_acenter">
                <NumberInput
                  :value="ruleForm[sitem.key_name]/( sitem.unit_value_multiple ? sitem.unit_value_multiple : 1 )"
                  :rightTips="sitem.unit"
                  :valueMultiple="sitem.unit_value_multiple"
                  :placeholder="sitem.placeholder"
                  @input="changeVal($event,sitem.key_name)"
                  @blur="formItemBlur($event.value,sitem.key_name)" >
                </NumberInput>
              </div>
              <el-select v-else-if=" sitem.display == 1 && sitem['input_type'] == 'single_select' "
                :value="ruleForm[sitem.key_name]"
                @change="selectChange($event,sitem.key_name)"
                placeholder="请选择"
                class="set-bgimg-select noborder flex1"
              >
                <el-option
                  v-for="item in sitem.enum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <checkBox v-else-if=" sitem.display == 1 && sitem['input_type'] == 'checkbox'" 
                :value="!!ruleForm[sitem.key_name]" :label="sitem.title" @change="formItemBlur($event,sitem.key_name)" class="margin_bot24"></checkBox>
              <div v-else-if=" sitem.display == 1 && sitem['input_type'] == 'text'" >
                <el-input v-model="ruleForm[sitem.key_name]" :placeholder="sitem.placeholder" class="modal-sets-inlineinput noborder bgc_fff" 
                @blur="formItemBlur($event.target.value,sitem.key_name)"></el-input>
              </div>
              <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'textarea'" >
                <ace :value="customStyle" @input="changeLessCode" @blur="formItemBlur($event,sitem.key_name)" ></ace>
              </div>
              <el-switch
                v-else-if="sitem.display == 1 && sitem['input_type'] == 'truefalse'" 
                @change="switchChange($event,sitem.key_name)"
                v-model="ruleForm[sitem.key_name]"
                :width="52"
                active-color="#E74362"
                inactive-color="#D4D4D4"
                class="">
              </el-switch>
              <div class="set-bgcolor " v-if="sitem.display == 1 && sitem['input_type'] == 'color_picker'">
                <div class="prel set-bgcolor-picker-wrapper">
                  <el-color-picker
                    :class="{'set-bgcolor-picker':true,'notValue': !ruleForm[sitem.key_name] }"
                    popper-class="set-bgcolor-dropdown"
                    v-model="ruleForm[sitem.key_name]"
                    :show-alpha="false"
                    color-format="hex"
                    @change="colorChange($event,sitem.key_name)"
                    @active-change="colorActiveChange"
                    :predefine="predefineColors">
                  </el-color-picker>
                  <span class="picker-colorvalue">{{sitem.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import Config from '@/libs/config';
import { mapGetters, mapMutations, mapState } from "vuex";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  name:"leftForm",
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    tabList:{
      type: Array,
      default: function() {
        return [];
      }
    },
    currentTab: [String, Number],
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
  },
  computed: {
    ...mapGetters([
      'getCustomStyleById'
    ]),
    tabListLen() {
      return this.tabList.length
    },
    customStyle() {
      return this.getCustomStyleById(this.$route.params.id) || "";
    },
    tabsLength() {
      let tabList = this.tabList;
      return tabList && Array.isArray( tabList ) ? tabList.length : 0
    },
  },
  components: {
    NumberInput,
    checkBox,
  },
  data() {
    return {
      activeTab: 0,
      //预定义颜色
      predefineColors: [
        '#FFFFFF', //为原始值，做历史记录用
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      throttleDelay:Config.realTimeInput
    };
  },
  watch:{
    currentTab(n) {
      // this.activeTab = this.currentTab;
    },
    tabList(n) {
      // 为了销毁tab之前的渲染
      this.activeTab = -1;
      setTimeout(() => {
        this.activeTab = 0;
      },10);
      // this.$forceUpdate();
    }
  },
  methods: {
    ...mapMutations(["SET_COURSE_FORM"]),
    switchChange(val,prop) {
      // 开关切换
      this.$emit("formItemBlur", val,prop,this.ruleForm)
    },
    changeLessCode(val) {
      // this.lessCss = val;
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    uploadImg(val,propName) {
      this.$emit("formItemBlur", val, propName,this.ruleForm)
    },
    changeVal(value, prop) {
      this.$emit("changeVal",value,prop,this.ruleForm);
    },
    richTextInput(value, prop) {
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    formItemBlur(value, prop) {
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.$emit('selectChange',e,prop,this.ruleForm)
    },
    colorChange(colorVal, prop) {
      // 当绑定值变化时触发
      this.$emit("formItemBlur", colorVal, prop,this.ruleForm)
    },
    colorActiveChange(activeVal) {
      // 面板中当前显示的颜色发生改变时触发
      // console.log("colorActiveChange",activeVal);
    },
  }
}
</script>

<style lang="less" scoped>
  .one_tab {
    /deep/.el-tabs__header {
      display: none;
      margin: 0;
    }
  }

  /deep/.el-tabs__active-bar {
    width: calc(100% / var(--tabs-num)) !important;
  }
</style>