<template>
  <div class="scroll-box width0" v-on:scroll.passive="onScroll" ref="scrollContainer" >
    <div ref="scrollInner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      containerHeight:0,
      innerHeight:0
    };
  },
  created() {
    this.$nextTick(()=>{
      this.containerHeight = this.$refs.scrollContainer && this.$refs.scrollContainer.clientHeight || 0;
      this.innerHeight = this.$refs.scrollInner && this.$refs.scrollInner.offsetHeight || 0;
    })
  },
  beforeDestroy() {

  },
  methods: {
    onScroll(event) {
      // 滚动监听
      this.innerHeight = this.$refs.scrollInner.offsetHeight;
      let scrollTop = event.target.scrollTop;

      // 触底了 触底10px时
      if (scrollTop + this.containerHeight - 10 >= this.innerHeight) {
        this.$emit('reachBottom')
      }
    },
  }
}
</script>

<style>

</style>