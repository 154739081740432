<template>
  <input type="text" :placeholder="placeholder" :value="value" @input="inputHandle" @blur="inputBlur" class="modal-sets-altinput" :style="{'--bgcolor':bgColor}">
</template>

<script>
export default {
  props: {
    value: [Number,String],
    placeholder: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "#FFFFFF"
    },
  },
  methods: {
    inputHandle(e) {
      this.$emit('input', e.target.value);
      //分发校验事件
      // this.$parent.$emit('validate'); 
    },
    inputBlur(e) {
      this.$emit('blur', e.target.value);
    },
  }
}
</script>

<style lang="less" scoped>
.modal-sets-altinput {
    box-sizing: border-box;
    background: #FFFFFF;
    border: none;
    border-bottom: 1px solid #D1D1D1;
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 2px 2px 2px 0px;
    font-size: 12px;
    color: #999;
    background-color: var(--bgcolor);
}
input::-webkit-input-placeholder,
input:-moz-input-placeholder,
input::-moz-input-placeholder,
input:-ms-input-placeholder {
    
}
</style>