<template>
  <div class="flex flex1 flex_acenter">
    <div v-if="prefix" class="fontsize_14 color_26 flexshrink padding_right8">{{prefix}}</div>
    <div class="number-input-wrapper prel flex flex_acenter flex_jcbetween flex1" :class="{'active': isFocusing, 'smallwidth': prefix}">
        <!-- $attrs可以获取来自父组件传过来的值，并展开放在当前标签上 -->
        <input v-if="!isSlider" type="number" name="" 
          :placeholder="placeholder"
          v-bind="$attrs" :value="currentValue" @input="inputHandle" @blur="inputBlur" @focus="inputFocus" :min="min" 
          @keydown.stop="inputKeydown"
          :style="{'border-color':borderColor}"
          class="modal-sets-inlineinput flex1">
        <input v-else type="number" name="" 
          :placeholder="placeholder"
          v-bind="$attrs" :value="currentValue" @input="inputHandle" @blur="sliderBlur" @focus="inputFocus" 
          @keydown.stop="inputKeydown"
          :min="extralData.min"
          :max="extralData.max"
          :step="extralData.step"
          :style="{'border-color':borderColor}"
          class="modal-sets-inlineinput flex1">
        <span v-if="rightTips" class="number-input-right padding_left4 flexshrink">{{rightTips}}</span>
    </div>
    <div v-if="suffix"  class="fontsize_14 color_26 flexshrink padding_left8">{{suffix}}</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    id:String,
    value: [Number,String],
    valueMultiple: Number, //值的倍数
    min: {
      type: [Number,String],
      default: 0,
      validator: min => /^\d+$/.test(min)
    },
    borderColor: {
      type: String,
      default: "#E4E4E4"
    },
    rightTips: {
      type: String,
      default: ""
    },
    keyName: {
      type: String,
      default: ""
    },
    form: {
      type: Object,
      default: function() {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      currentValue:this.value,
      isFocusing: false, //是否输入中
      extralKeyname:["component-_scaleStart","component-_scaleEnd","component-_scaleStep","component-_attempts"],
    };
  },
  watch: {
    value: {
      immediate:true,
      handler(n) {
        if(n != undefined) {
          this.currentValue = n;
        }
      },
    },
    keyName: {
      handler(n) {
        if(n) {
          // component-_scaleStart
          // component-_scaleEnd
          // component-_scaleStep
          // component-_attempts
        }
      },
      immediate:true
    },
  },
  computed: {
    isSlider() {
      // 是否滑动条设置值
      let keyName = this.keyName;
      let targetIndex = this.extralKeyname.findIndex(v => v == keyName)
      return targetIndex != -1
    },
    extralData() {
      if(!this.isSlider) return {};
      let obj = {};
      let Max = Number(this.form['component-_scaleEnd']);
      let Step = Number(this.form['component-_scaleStep']);
      let Min = Number(this.form['component-_scaleStart']);
      switch(this.keyName) {
        case "component-_scaleStart":
          obj.max = Max;
          obj.step = Step;
          break;
        case "component-_scaleEnd":
          obj.min = Min;
          obj.step = Step;
          break;
        case "component-_scaleStep":
          obj.min = 1;
          obj.max = Max;
          break;
      }
      // console.log('obj',obj)
      return obj;
    },
  },
  methods: {
    ...mapMutations(["inputMiniHeight"]),
    updateValue() {
      //用于刷新
      this.currentValue = this.value;
      this.$forceUpdate();
    },
    inputKeydown(event) {
      if(event.keyCode === 13) {
        //数字输入框，阻止回车事件
        // event.preventDefault();
      }
    },
    inputHandle(e) {
      this.currentValue = e.target.value;

      if(this.valueMultiple != undefined && this.valueMultiple != 0 && !Number.isNaN(this.valueMultiple)) {
        this.$emit('input', e.target.value * this.valueMultiple );
      }else {
        this.$emit('input', e.target.value);
      }
      
      //显示高度条
      // _menuHeader minimumHeights
      if(this.keyName && this.keyName.indexOf("minimumHeights") > -1) { 
        this.inputMiniHeight({
          id: this.id,
          isShow: true,
          value: e.target.value
        });
      }
      //分发校验事件
      // this.$parent.$emit('validate'); 
    },
    inputBlur(e) {
      // 失去焦点
      this.isFocusing = false;
      // 是否基于倍数
      let baseMultiple = this.valueMultiple != undefined && this.valueMultiple != 0 && !Number.isNaN(this.valueMultiple);

      // 基于倍数
      if(baseMultiple) {
        if(this.min && this.currentValue * this.valueMultiple < this.min) {
        // 设置了最小值时
          this.currentValue = this.min / this.valueMultiple;
          this.$emit('blur', {value:this.min, el:this.$el, callback:this.updateValue});
        }else {
          this.$emit('blur', {value:this.currentValue * this.valueMultiple, el:this.$el, callback:this.updateValue} );
        }
        return;
      }

      // 常规-无倍数
      if(this.min && this.currentValue < this.min) {
        // 设置了最小值时
        this.currentValue = this.min;
        this.$emit('blur', {value:this.currentValue, el:this.$el, callback:this.updateValue});
      }else {
        this.$emit('blur', {value:this.currentValue, el:this.$el, callback:this.updateValue});
      }
      
      //隐藏高度条
      this.keyName && this.inputMiniHeight({
        id: null,
        isShow: false,
        value: null
      });
    },
    inputFocus(e) {
      // 聚焦
      this.isFocusing = true;
      // this.$emit("focus", this.currentValue);
      if(this.keyName && this.keyName.indexOf("minimumHeights") > -1) { 
        this.inputMiniHeight({
          id: this.id,
          isShow: true,
          value: this.currentValue
        });
      }
    },
    sliderBlur(e) { //额外对slider的处理
      // 失去焦点
      let value = e.target.value;
      let sliderMax = Number(this.form['component-_scaleEnd']);
      let sliderMin = Number(this.form['component-_scaleStart']);
      let sliderStep = Number(this.form['component-_scaleStep']);
      let temp = "";
      this.isFocusing = false;

      // 不是全部有值，不做校验
      if(!this.allHasValue(value)) {
        this.$emit('blur', {value, el:this.$el, callback:this.updateValue});
        return;
      }
      switch(this.keyName) {
        // 开始刻度值：小于结束值；
        case "component-_scaleStart":
          value = value > sliderMax ? (sliderMax-sliderStep) : value;
          this.currentValue = value;
          break;
          // 结束刻度值：大于开始值；并且与开始值差值是 增量倍数
        case "component-_scaleEnd":
          temp = ( parseInt((value - sliderMin) / sliderStep )) * sliderStep + sliderMin;
          if(typeof temp != "number") temp = Number(temp);
          value = value < sliderMin+sliderStep ? sliderMin+sliderStep :  Number(e.target.value) === temp ? e.target.value : temp.toFixed(2);
          this.currentValue = value;
          break;
        case "component-_scaleStep":
          value = (value >= (sliderMax - sliderMin) || value <= 0) ? 1 : value;
          this.currentValue = value;
          break;
      }
      this.$emit('blur', {value, el:this.$el, callback:this.updateValue});
    },
    allHasValue(value) {
      // 全部有值
      let keyArr = ['component-_scaleEnd', 'component-_scaleStart', 'component-_scaleStep'];
      let keyName = this.keyName;
      let otherArr = keyArr.filter(v => v !== keyName);
      let otherIndex = otherArr.findIndex(v => {
        let temp = this.form[v];
        return this.form[v] == undefined || typeof temp == "string" && !temp.trim();
      });
      // console.log('allHasValue',otherIndex == -1 && value != undefined);
      return otherIndex == -1 && value != undefined;
    },
  },
}
</script>

<style lang="less" scoped>
.number-input-wrapper {
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  width: 100%;
  &.active {
    border-color: #A1A1A1;
  }
  input::placeholder {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
  }
}
.modal-sets-inlineinput {
    box-sizing: border-box;
    background: #FFFFFF;
    border: none;
    border-radius: 4px;
    height: 34px;
    // line-height: 34px;
    line-height: 1px; //解决：中文输入，导致光标上移
    padding: 2px 2px 2px 15px;
    vertical-align: middle;
}
.number-input-right {
  padding: 0 8px;
  font-size: 14px;
  color: #BFBFBF;
}

.smallwidth .modal-sets-inlineinput{
  width: 86px;
}
</style>