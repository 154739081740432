<template>
  <el-form @submit.native.prevent action="false" :model="ruleForm" class="height_100p">
    <div class="scroll-parent hidden">
      <div class="scroll-box  width0" :style="{'padding-bottom':'20px'}">
        <div v-for="sitem in propList" :key="sitem.id" class="modal-sets-item padding_0_16"
          :style="{'padding-left':scrollBoxPaddingLR+'px','padding-right':scrollBoxPaddingLR+'px',}"
          :class="{'border_topED padding_top25': sitem.display == 1 && sitem.pivot && sitem.pivot.dividing_line == 1 }"
          v-show="sitem.display == 1">
          <p v-if="sitem.display == 1 && sitem.hidden_title != true" class="title"  :style="{'font-size': titleFontSize +'px'}">{{ sitem.title }}</p>
          <p v-if="sitem.display == 1 && sitem.help" class="fontsize_12 color_999 margin_bot10" :style="{'font-size': (titleFontSize-2) +'px'}">{{sitem.help}}</p>
          <div v-if="sitem.properties" 
            :style="{'--color-picker-column': sitem['itemNum']}"
            :class="{'flex flex_jcbetween': sitem.properties_name == '_backgroundStyles' || sitem.properties[0]['input_type'] === 'color_picker_mini',
            'flex flexwrap':sitem['itemNum']}">
            <div v-for="(ritem, prind) in sitem.properties" :key="ritem.id"
              :class="{'margin_bot20': prind !== sitem.properties.length - 1 && ritem['input_type'] != 'Checkbox' && ritem['input_type'] != 'truefalse' && !(sitem['itemNum'] && sitem['key_name'].indexOf('trickle') >= 0),
              'set-bgimg-select-container left':  ritem.display == 1 && ritem['input_type'] == 'single_select',
              'multiColumns':sitem['itemNum']
               }">
              <p v-if="ritem.display == 1 && ritem.hidden_title != true && (ritem['input_type'] == 'number' || sitem['input_name'] == '_graphic')" class="margin_bot8 fontsize_16 color_222" :style="{'font-size': titleFontSize +'px'}">{{ritem.title}}</p>
              <ckeditor v-if=" ritem.display == 1 && ritem['input_type'] == 'richtext_mini'" 
                type="simple" 
                :id=" 'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                :value="ruleForm[ritem.key_name]" 
                :throttle="throttleDelay"
                :placeholder="ritem.placeholder"
                @input="richTextInput($event,ritem.key_name)" 
                @blur="formItemBlur($event,sitem.key_name)" >
              </ckeditor>
              <ckeditor v-else-if="ritem.display == 1 && ritem['input_type'] == 'richtext'"
                :type="'rich'" 
                :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                :value="ruleForm[ritem.key_name]" 
                :throttle="throttleDelay"
                :placeholder="ritem.placeholder"
                @input="richTextInput($event,ritem.key_name)" 
                @blur="formItemBlur($event,sitem.key_name)">
              </ckeditor>
              <div v-else-if="ritem.display == 1 && (ritem['input_type'] == 'number' || ritem['input_type'] == 'untitled_number')" class="flex flex_acenter" >
                <NumberInput
                  class="flex1"
                  :value="ruleForm[ritem.key_name]/( ritem.unit_value_multiple ? ritem.unit_value_multiple : 1 )"
                  :rightTips="ritem.unit"
                  :valueMultiple="ritem.unit_value_multiple"
                  :placeholder="ritem.placeholder"
                  :min="ritem.min"
                  :prefix="ritem.prefix"
                  :suffix="ritem.suffix"
                  @input="changeVal($event,ritem.key_name)"
                  @blur="formItemBlur($event.value,ritem.key_name)" >
                </NumberInput>
              </div>
              <el-select
                v-else-if="ritem.display == 1 && ritem['input_type'] == 'single_select'"
                :value="ruleForm[ritem.key_name]"
                @change="selectChange($event,ritem.key_name)"
                placeholder="请选择"
                class="set-bgimg-select noborder">
                <el-option
                  v-for="item in ritem.enum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value" >
                </el-option>
              </el-select>
              <assetupload
                v-else-if="ritem.display == 1 && ritem['input_type'] == 'image'"
                :height="129"
                :uploadText="ritem.upload_text"
                :src="ruleForm[ritem.key_name]"
                @change="uploadImg($event,ritem.key_name)"
              ></assetupload>
              <checkBox v-else-if=" ritem.display == 1 && ritem['input_type'] == 'checkbox'" 
                :value="getCheckValue(!!ruleForm[ritem.key_name], ritem)" :label="ritem.title" :labelFontSize="12" :labelPaddingLeft="5" @change="checkboxBlur($event,ritem.key_name,ritem)" class=""></checkBox>
              <el-switch
                v-else-if="ritem.display == 1 && ritem['input_type'] == 'truefalse'" 
                @change="switchChange($event,ritem.key_name)"
                v-model="ruleForm[ritem.key_name]"
                :width="52"
                active-color="#E74362"
                inactive-color="#D4D4D4"
                class="">
              </el-switch>
              <el-input v-if="ritem.display == 1 && ritem['input_type'] === 'text'"
                :value="ruleForm[ritem.key_name]" :placeholder="ritem.placeholder" 
                class="modal-sets-inlineinput noborder bgc_fff" 
                @input="formItemBlur($event,sitem.key_name)"
                @blur="formItemBlur($event.target.value,sitem.key_name)"></el-input>
              <div class="set-bgcolor flex1" v-else-if="ritem.display == 1 && (ritem['input_type'] == 'color_picker' || ritem['input_type'] == 'color_picker_mini')">
                <div class="prel set-bgcolor-picker-wrapper" :class="{'width144': ritem['input_type'] == 'color_picker_mini'}">
                  <fd-color-picker
                    :class="{'set-bgcolor-picker':true,'notValue': !ruleForm[ritem.key_name] }"
                    popper-class=""
                    :value="themeVariables[ritem.key_name]"
                    :show-alpha="true"
                    color-format="hex"
                    @change="colorChange($event,ritem.key_name)"
                    @active-change="colorActiveChange"
                    :predefine="predefineColors">
                  </fd-color-picker>
                  <span class="picker-colorvalue">{{ritem.title}}</span>
                </div>
              </div>
            </div>
          </div>
          <ckeditor v-if=" sitem.display == 1 && sitem['input_type'] == 'richtext_mini' "
            :type="'simple'" :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
            :value="ruleForm[sitem.key_name]" 
            :throttle="throttleDelay"
            :placeholder="sitem.placeholder"
            @input="richTextInput($event,sitem.key_name)" 
            @blur="formItemBlur($event,sitem.key_name)" >
          </ckeditor>
          <ckeditor v-else-if="sitem.display == 1 && sitem['input_type'] == 'richtext' "
            :type="'rich'" 
            :id="'rightForm-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
            :value="ruleForm[sitem.key_name]" 
            :throttle="throttleDelay"
            :placeholder="sitem.placeholder"
            @input="richTextInput($event,sitem.key_name)" 
            @blur="formItemBlur($event,sitem.key_name)" >
          </ckeditor>
          <div v-else-if="sitem.display == 1 && (sitem['input_type'] == 'number' || sitem['input_type'] == 'untitled_number')" class="flex flex_acenter" >
            <NumberInput
              class="flex1"
              :value="ruleForm[sitem.key_name]/( sitem.unit_value_multiple ? sitem.unit_value_multiple : 1 )"
              :rightTips="sitem.unit"
              :valueMultiple="sitem.unit_value_multiple"
              :placeholder="sitem.placeholder"
              :min="sitem.min"
              :prefix="sitem.prefix"
              :suffix="sitem.suffix"
              @input="changeVal($event,sitem.key_name)"
              @blur="formItemBlur($event.value,sitem.key_name)"
            ></NumberInput>
          </div>
          <el-select
            v-else-if="sitem.display == 1 && sitem['input_type'] == 'single_select'"
            :value="ruleForm[sitem.key_name]"
            @change="selectChange($event,sitem.key_name)"
            placeholder="请选择"
            class="set-bgimg-select noborder"
          >
            <el-option
              v-for="item in sitem.enum"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <checkBox v-else-if="sitem.display == 1 && sitem['input_type'] == 'checkbox'" 
            :value="getCheckValue(!!ruleForm[sitem.key_name], sitem)" :label="sitem.title" @change="checkboxBlur($event,sitem.key_name,sitem)" class="margin_bot24">
          </checkBox>
          <el-switch
            v-else-if="sitem.display == 1 && sitem['input_type'] == 'truefalse'" 
            @change="switchChange($event,sitem.key_name)"
            v-model="ruleForm[sitem.key_name]"
            :width="52"
            active-color="#E74362"
            inactive-color="#D4D4D4"
            class="">
          </el-switch>
          <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'text'" class="" >
            <el-input :value="ruleForm[sitem.key_name]" :placeholder="sitem.placeholder" 
              class="modal-sets-inlineinput noborder bgc_fff" 
              @input="formItemBlur($event,sitem.key_name)"
              @blur="formItemBlur($event.target.value,sitem.key_name)"></el-input>
          </div>
          <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'textarea'" class="modal-sets-item" >
            <ace :value="customStyle" @input="changeLessCode($event,sitem.key_name)" @blur="formItemBlur($event,sitem.key_name)" ></ace>
          </div>
          <assetupload
            v-else-if="sitem.display == 1 && sitem['input_type'] == 'image'"
            :src="ruleForm[sitem.key_name]"
            :height="129"
            :uploadText="sitem.upload_text"
            @change="uploadImg($event,sitem.key_name)"
          ></assetupload>
          <AltInput v-else-if="sitem.display == 1 && sitem['input_type'] == 'alt'"
            bgColor="#fafafa"
            :placeholder="sitem.title"
            :value="ruleForm[sitem.key_name]"
            @input="changeVal($event,sitem.key_name)"
            @blur="formItemBlur($event,sitem.key_name)">
          </AltInput>
          <!-- <div class="set-bgcolor modal-sets-item">
            <p class="title">背景色</p>
            <div class="prel set-bgcolor-picker-wrapper">
              <el-color-picker
              class="set-bgcolor-picker"
              v-model="color"
              :show-alpha="false"
              color-format="hex"
              @change="colorChange"
              @active-change="colorActiveChange"
              :predefine="predefineColors">
            </el-color-picker>
            <span class="picker-colorvalue">{{color}}</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import Config from '@/libs/config';
import { mapGetters, mapMutations, mapState } from "vuex";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import AltInput from "@/renderer/components/common/altInput.vue";
export default {
  name:"leftForm",
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
    propList:{
      type: Array,
      default: function() {
        return [];
      }
    },
    titleFontSize: { //每一项标题 字体大小
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 16
    },
    scrollBoxPaddingLR: { //scrollBox左右padding值
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 13
    }
  },
  components: {
    NumberInput,
    checkBox,
    AltInput
  },
  data() {
    return {
      activeTab: 0,
      //预定义颜色
      predefineColors: [
        'rgba(255, 69, 0, 0.68)', //为原始值，做历史记录用
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      throttleDelay:Config.realTimeInput
    };
  },
  computed: {
    ...mapGetters([
      'getCustomStyleById',
      'getThemeById'
    ]),
    customStyle() {
      return this.getCustomStyleById(this.$route.params.id) || "";
    },
    themeVariables() {
      // 主题变量对象
      return this.getThemeById(this.$route.params.id) || {};
    }
  },
  methods: {
    ...mapMutations(["SET_COURSE_FORM"]),
    getCheckValue(value, item) {
      let { checkbox_enum } = item;
      // checkbox_enum : {checked: "visible", no_checked: "hidden"} {visible:true, hidden: false}
      if(checkbox_enum) {
        let trueVal = this.ruleForm[item.key_name];
        return checkbox_enum[trueVal];
      }
      return value;
    },
    colorActiveChange(val,prop) {
      // console.log('colorActiveChange',prop, val);
    },
    colorChange(colorVal, prop) {
      // 当绑定值变化时触发
      // console.log("colorChange",colorVal);
      this.$emit("formItemBlur", colorVal, prop,this.ruleForm)
    },
    switchChange(value, prop) {
      this.ruleForm[prop] = value;
      this.$forceUpdate()
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    changeLessCode(val, propName) {
      this.lessCss = val;
      this.$emit("formItemInput", val, propName,this.ruleForm);
    },
    uploadImg(val,propName) {
      this.$emit("formItemBlur", val, propName,this.ruleForm)
    },
    changeVal(value, prop) {
      this.$emit("formItemBlur", value, prop,this.ruleForm);
    },
    richTextInput(value, prop) {
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    formItemBlur(value, prop) {
      this.ruleForm[prop] = value;
      this.$forceUpdate()
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    checkboxBlur(value, prop, item) {
      let { checkbox_enum } = item;
      if(checkbox_enum) {
        Object.keys(checkbox_enum).forEach(key => {
          if(value === checkbox_enum[key]) {
            value = key;
          }
        });
      }
      this.ruleForm[prop] = value;
      this.$forceUpdate()
      this.$emit("formItemBlur", value, prop,this.ruleForm)
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.$set(this.ruleForm, prop, e);
      this.$forceUpdate();
      this.$emit('selectChange',e,prop,this.ruleForm)
    },
  }
}
</script>

<style lang="less" scoped>
  // 两列
  .multiColumns {
    margin-right: 8px;
    width: calc(100%/var(--color-picker-column) - 8px);
    flex-shrink: 0;
    // &:nth-child(2n) {
    //   margin-left: 16px;
    // }
    &:last-child {
      margin-right: 0;
    }
  }
</style>