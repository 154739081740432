<template>
  <transition-group name="flip-list" tag="div">
    <div class="item resource-wrapper" v-for="(litem,lind) in list" :key="lind + 'i'">
      <div class="resource-item flex flex_jcbetween flex_acenter">
        <div class="resource-item-content flex flex_acenter">
          <div class="resource-item-title fontsize_14 color_222 flex flex_acenter">
            <img  v-if="setProps.show_title_icon && setProps.title_icon_group" 
              :src="getTitleIcon(litem[setProps.title_icon], setProps.title_icon_group, litem)" 
              alt="" class="title_icon">
            <img  v-else-if="setProps.show_title_icon && setProps.title_icon" 
              :src="getTitleImg(litem, setProps)" 
              alt="" class="title_icon">
            <div class="padding_left10 pover" style="width:206px;" v-html="litem[setProps.title]"></div>
          </div>
        </div>
        <checkBox v-if="setProps.extra_action && setProps.extra_action.display_type === 'checkbox'" 
            :value="litem[setProps.extra_action.field_name]" :label="setProps.extra_action.title" 
            labelFontSize="14" @change="changeCheck($event,{item:litem,prop:setProps.extra_action.field_name,index:lind})"></checkBox>
        <div style="width:250px;" class="flex flex_end">
          <div class="resource-item-tools flex flex_acenter" >
            <div v-for="(bitem,bind) in setProps.action" :key="'rbtn'+bind">
              <div v-if="bitem.display_type === 'icon'" 
                class="resource-item-action pointer prel "
                :class="{'split-line': bind !=  setProps.action.length - 1}"
                @click.stop="tapAction(litem,lind,bitem.type)" >
                <i class="icon-edit-black fontsize_14 color_222 icon_color_222" v-if="bitem.title == '编辑'"></i>
                <i class="icon-menu-del fontsize_14 all_del_icon icon_color_222 icon_hover_E74362" v-else-if="bitem.title == '删除'"></i>
                <i class="icon-page-copy fontsize_14 icon_color_222" v-else-if="bitem.type == 'copy'"></i>
                <span class="icon-tips fontsize_12 color_222">{{bitem.title}}</span>
              </div>
              <div v-else-if="bitem.display_type === 'button'" @click.stop="tapAction(litem,lind,bitem.type)" class="margin_left5 pointer bgc_F7F7F7">
                {{bitem.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  props: {
    setProps: Object,
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
  },
  data() {
    return {

    };
  },
  components: {
    checkBox
  },
  methods: {
    changeCheck(value,{item, prop, index}) {
      this.$emit("changeCheck", {item, prop, index, value});
    },
    tapAction(item,ind,type) {
      switch(type) {
        case "edit":
          // 编辑
          this.$emit("edit",item,ind);
          break;
        case "delete":
          // 删除
          this.$emit("del",item,ind);
          break;
      }
    },
    getTitleIcon(type, typeGroup, item) {
      
      let { ext_icon_group, default_icon } = this.setProps;
      let { _link } = item;
      let dotIndex = _link.lastIndexOf(".");

      return ext_icon_group[_link.slice(dotIndex+1)] || default_icon || type;
    },
    getTitleImg(item,setprop) {
      // console.log("getTitleImg",item, setprop)
      let propArr = setprop.title_icon.indexOf('.') > -1 ? setprop.title_icon.split(".") : setprop.title_icon;
      if(propArr.length == 1) return item[propArr[0]]
      else if(propArr.length == 2) return item[propArr[0]][propArr[1]]
      return ""
    }
  },
}
</script>

<style lang="less" scoped>
  /deep/.el-checkbox {
    margin-right: 5px;
  }
  .flip-list-move {
    transition: transform 1s;
  }
  .resource-wrapper {
    box-sizing: border-box;
    margin-bottom: 8px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    &.is-active {
      .resource-item {
        background-color: #b0e0f6;
      }
    }
  }
  // 头部
  .resource-item {
    box-sizing: border-box;
    padding: 8px 8px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
  .resource-item-content {
    padding-left: 8px;
    .resource-item-title {
      // padding-left: 14px;
      width: 144px;
    }
  }
  .resource-item-tools {
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    background-color: #F7F7F7;
    
    .resource-item-action {
      // padding: 0 8px;
      &:hover {
        & > .icon-tips {
          display: block;
        }
      }
      >i {
        display: block;
        position: relative;
        border-radius: 4px;
        width: 33px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #505050;
        background-color: #FAFAFA;
        &:hover {
          background-color: #e4e4e4;
          .icon-tips {
            display: block;
          }
        }
      }
    }

    .icon-tips {
      display: none;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translateY(100%) translateX(-50%);
      background: #F2F2F1;
      border: 1px solid #DADADA;
      border-radius: 1px;
      padding: 0 6px;
      height: 17px;
      line-height: 17px;
      white-space:nowrap;
    }
  }

  .title_icon {
    flex-shrink: 0;
    border-radius: 1.25px;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  // 分割线
  .split-line {
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      content: "";
      display: block;
      width: 1px;
      height: 10px;
      background-color: #D4D4D4;
    }
  }
</style>