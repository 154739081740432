<template>
  <section class="coursepanel-main flex1 bgc_fff flex flex_acenter flexcolumn " >
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>
<style lang="less" scoped>
  .coursepanel-main {
    // height: calc(100%);
  }

</style>
