import * as request from '@/libs/utils/request'
import Config from '@/libs/config'

// 获取主题列表
export function getThemeList({course_id, page, limit}) {
  return request.get({
    url: "/api/"+ 'V2' +"/theme/list/get?course_id="+course_id +"&page="+page +"&limit="+limit
  })
}

// 获取模板列表
export function getTemplateList({course_id, page, limit}) {
  return request.get({
    url: "/api/"+ "V2" +"/theme/template/list/get?course_id="+course_id +"&page="+page +"&limit="+limit
  })
}

// 获取主题配置
export function getThemeSetting({course_id, theme_key}) {
  return request.get({
    url: "/api/"+ "V2" +"/theme/setting/get?course_id="+course_id +"&theme_key="+theme_key
  })
}

// 获取当前主题配置
export function getCourseThemeSetting({course_id}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/theme/course/setting/get?course_id="+course_id
  })
}

// 主题设置-保存
export function saveThemeSetting({course_id, theme_key, theme_variables}) {
  return request.post({
    url: "/api/"+ "V2" +"/theme/save",
    data: {
      course_id,
      theme_key,
      theme_variables
    }
  })
}

// 当前课程-主题设置-保存
export function saveCourseThemeSetting({course_id,  theme_variables}) {
  return request.post({
    url: "/api/"+ Config.apiversion +"/theme/courseSave",
    data: {
      course_id,
      theme_variables
    }
  })
}

// 主题设置-保存到模板
export function saveThemeTotemplate({name, course_id, theme_key, theme_variables}) {
  return request.post({
    url: "/api/"+ "V2" +"/theme/saveToTemplate",
    data: {
      name, 
      course_id,
      theme_key,
      theme_variables
    }
  })
}

// 主题设置-切换主题
export function switchTheme({type, course_id, theme_key, theme_template_id}) {
  let url = "/api/"+ "V2" +"/theme/switchTheme?course_id="+course_id + "&theme_key=" + theme_key + "&type=" + type;
  if(type == 'theme_template') { //模板
    url += "&theme_template_id=" + theme_template_id
  }
  return request.get({
    url: url,
  })
}

// 复制课程+切换主题
export function copyCourseAndSwitchTheme({type, course_id, is_switch = 1, theme_key, theme_template_id}) { //is_switch为1(代表切换主题): type 和 theme_key必填
  let url = "/api/"+ Config.apiversion +"/courses/copy?course_id="+course_id;
  if(type == 'theme_template') { //模板
    url += "&theme_template_id=" + theme_template_id
  }
  if(theme_key) {
    url += "&theme_key=" + theme_key
  }
  if(type) {
    url += "&type=" + type
  }
  if(is_switch) {
    url += "&is_switch=" + is_switch
  }
  return request.get({
    url: url,
  })
}

// 获取模板配置
export function getTemplateSetting({course_id, id}) { //id-模板id
  return request.get({
    url: "/api/"+ Config.apiversion +"/theme/template/setting/get?course_id="+course_id +"&id="+id
  })
}

// 复制模板
export function copyTemplate({id}) { //id-模板id
  return request.get({
    url: "/api/"+ Config.apiversion +"/theme/template/copy?id="+id
  })
}

// 重置主题
export function resetTheme({course_id, theme_key}) {
  return request.get({
    url: "/api/"+ Config.apiversion +"/theme/resetting?course_id="+course_id + "&theme_key="+theme_key
  })
}

// 删除模板
export function delTemplate({course_id,id}) { //id-模板id
  return request.get({
    url: "/api/"+ "V2" +"/theme/template/delete?course_id="+course_id+"&id="+id
  })
}

// 模板-保存
export function saveTemplateSetting({course_id, id, theme_variables}) {
  return request.post({
    url: "/api/"+ "V2" +"/theme/template/save",
    data: {
      course_id,
      id,
      theme_variables
    }
  })
}

// 模板-修改名称
export function renameTemplate({name,id}) { //id-模板id
  return request.get({
    url: "/api/"+ Config.apiversion +"/theme/template/rename?id="+id+"&name="+name
  })
}

export function getCourseCurrentTheme({course_id}) {
  return request.get({
    url: "/api/" + Config.apiversion + "/theme/course?course_id="+course_id,
  })
}
